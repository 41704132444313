import React from 'react';
import MyRouter from "./Router";


function App() {
  return (
    <div className="App">
        <MyRouter/>
    </div>
  );
}

export default App;
